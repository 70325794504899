import React, { FunctionComponent } from 'react'

// Sections
import {
  FeaturedPosts,
  Hero,
  PartnersSection,
  TestimonialsSlider,
  WeAreFocusedOnSection,
  ArticleBoxesSection,
  CaseStudiesHighlights,
  NewsletterForm,
  RateCardForm,
  Statistics,
  MarkdownWYSIWYG,
  IconList,
  CareersBenefitsSection,
  CoreValuesSection,
  TeamSection,
  ServiceBoxesSection,
  MessageSentSection,
  ListOfPosts,
  JobOffersSection,
  HeroSecondaryVariant,
  HowWeWorkSection,
  OurRatesSection,
  ContactUsSection,
  ListOfCaseStudies,
} from '@/components'

type BodyType = {
  NotFound: typeof ComponentNotFound
  'section-featured-posts': typeof FeaturedPosts
  'section-hero': typeof Hero
  'section-banner': typeof PartnersSection
  'section-slider': typeof TestimonialsSlider
  'section-core-values': typeof CoreValuesSection
  'section-team-content': typeof TeamSection
  'section-we-are-focused-on': typeof WeAreFocusedOnSection
  'section-read-our-case-study': typeof CaseStudiesHighlights
  'section-contact-form': typeof NewsletterForm
  'section-rate-card-form': typeof RateCardForm
  'section-statistics': typeof Statistics
  'section-markdown': typeof MarkdownWYSIWYG
  'section-icon-list': typeof IconList
  'section-article-boxes': typeof ArticleBoxesSection
  'section-benefit-section': typeof CareersBenefitsSection
  'section-service-boxes': typeof ServiceBoxesSection
  'section-message-sent': typeof MessageSentSection
  'section-list-of-posts': typeof ListOfPosts
  'section-job-offers': typeof JobOffersSection
  'section-contact-us-form': typeof ContactUsSection
  'section-hero-secondary-variant': typeof HeroSecondaryVariant
  'section-how-we-work': typeof HowWeWorkSection
  'section-our-rates': typeof OurRatesSection
  'section-list-of-case-studies': typeof ListOfCaseStudies
}

const ComponentNotFound = ({ template }: { template: keyof BodyType }) => (
  <p>
    Component <strong>{template}</strong> Not Found
  </p>
)

const bodyMap: BodyType = {
  NotFound: ComponentNotFound,
  'section-featured-posts': FeaturedPosts,
  'section-hero': Hero,
  'section-banner': PartnersSection,
  'section-slider': TestimonialsSlider,
  'section-core-values': CoreValuesSection,
  'section-team': TeamSection,
  'section-we-are-focused-on': WeAreFocusedOnSection,
  'section-read-our-case-study': CaseStudiesHighlights,
  'section-contact-form': NewsletterForm,
  'section-rate-card-form': RateCardForm,
  'section-statistics': Statistics,
  'section-markdown': MarkdownWYSIWYG,
  'section-icon-list': IconList,
  'section-article-boxes': ArticleBoxesSection,
  'section-benefit-section': CareersBenefitsSection,
  'section-service-boxes': ServiceBoxesSection,
  'section-message-sent': MessageSentSection,
  'section-list-of-posts': ListOfPosts,
  'section-job-offers': JobOffersSection,
  'section-contact-us-form': ContactUsSection,
  'section-hero-secondary-variant': HeroSecondaryVariant,
  'section-how-we-work': HowWeWorkSection,
  'section-our-rates': OurRatesSection,
  'section-list-of-case-studies': ListOfCaseStudies,
}

interface Props {
  template: keyof BodyType
}

const ForestryConnector: FunctionComponent<Props> = ({ template, ...rest }) => {
  const SelectedComponent = typeof bodyMap[template] === 'function' ? bodyMap[template] : bodyMap.NotFound
  return <SelectedComponent template={template} {...rest} />
}
export default ForestryConnector
