import { graphql, Link } from 'gatsby'
import React from 'react'

import { SharePost } from '@/components'
import SEO from '@/components/seo'
import ConnectForestryBody from '@/helpers/connectForestryBody'
import { ArrowBackIconComponent } from '@/icons'
import { Image, Label } from '@/uikit'

interface Props {
  data: GatsbyTypes.CaseStudyTemplateQuery
}

const CaseStudyPage: React.FC<Props> = ({ data }) => {
  const { frontmatter } = data?.markdownRemark || {}

  function renderComponents(components) {
    if (components.length) {
      return components.map((component, i) => {
        return <ConnectForestryBody key={i} {...component} />
      })
    }

    return <h2>This Page doesn't have any sections in Forestry</h2>
  }
  return (
    <>
      <SEO title={data?.markdownRemark.frontmatter.meta_tags.title} meta={data?.markdownRemark.frontmatter.meta_tags} />
      <section className="pb-23 md:pb-30">
        <div className="bg-white dark:bg-black-lighter pb-5 px-7.5 mb-24">
          <div className="grid md:grid-cols-2 mx-auto max-w-1250">
            <div className="col-span-full mt-5 md:mt-18 flex items-center w-full max-w-1250 mx-auto print:max-w-540 print:mx-auto print:md:max-w-770">
              <Link to="/case-studies" className="group flex items-center h-10">
                <ArrowBackIconComponent className="dark:text-text-dm md:w-10 md:h-10 w-6 h-6 md:mt-0 transform group-hover:-translate-x-2 duration-500 print:hidden" />
                <Label>
                  <span className="ml-5 md:ml-10 inline-block print:mt-10 print:ml-0">Case study</span>
                </Label>
              </Link>
            </div>
            <div className="sm:pt-15 pt-5">
              <div className="max-w-540 mx-auto md:max-w-770 md:mt-5 md:mb-24">
                <div className="ani ani-blur ani-left max-w-80 mb-4 flex">
                  {frontmatter?.cs_image_light && (
                    <Image
                      objectFit="contain"
                      className="dark:hidden"
                      src={frontmatter.cs_company_logo_light}
                      alt="company logo"
                    />
                  )}
                  {frontmatter?.cs_image_dark && (
                    <Image
                      objectFit="contain"
                      className="hidden dark:block"
                      src={frontmatter.cs_company_logo_dark}
                      alt="company logo"
                    />
                  )}
                </div>
                <div className="mb-7.5 relative semiXl:-mr-20 pr-10">
                  <h1 className="mt-4 sm:mb-10 text-text text-32 sm:text-40 font-bold dark:text-gray-darker font-ilisarniq tracking-tightest md:text-5xl">
                    <span className="leading-tight">{frontmatter?.title}</span>
                  </h1>
                </div>
                <div className="flex-1">
                  <p className="text-blue-dark dark:text-orange font-bold font-inter leading-5 mt-7">Industry</p>
                  <h3 className="font-inter text-base leading-medium dark:text-gray-darker mb-10 sm:mb-0">
                    {frontmatter?.industry}
                  </h3>
                </div>
              </div>
            </div>
            <div className="ani ani-blur ani-scale flex justify-end print:hidden semiXl:-mr-20 semiXl:-mt-15 mt-10">
              {frontmatter?.cs_image_light && (
                <Image
                  objectFit="contain"
                  className="dark:hidden"
                  src={frontmatter.cs_image_light}
                  alt="abstract graphic"
                />
              )}
              {frontmatter?.cs_image_dark && (
                <Image
                  objectFit="contain"
                  className="hidden dark:block"
                  src={frontmatter.cs_image_dark}
                  alt="abstract graphic"
                />
              )}
            </div>
          </div>
        </div>
        <div>
          {renderComponents(frontmatter.body)}
          <div className="max-w-540 mx-auto md:max-w-770">
            {frontmatter && <SharePost title={frontmatter.title} excerpt={frontmatter.excerpt} />}
          </div>
        </div>
      </section>
    </>
  )
}

export default CaseStudyPage
export const caseStudyQuery = graphql`
  query CaseStudyTemplateQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      rawMarkdownBody
      frontmatter {
        title
        url
        industry
        cs_company_logo_light {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, height: 35, formats: [WEBP])
          }
          extension
          publicURL
        }
        cs_company_logo_dark {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, height: 35, formats: [WEBP])
          }
          extension
          publicURL
        }
        excerpt
        meta_tags {
          title
          description
          og_image {
            childImageSharp {
              gatsbyImageData(width: 1500, formats: [PNG], placeholder: BLURRED, layout: FIXED)
            }
            relativePath
          }
          nofollow
          noindex
        }
        cs_image_light {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 690, formats: [WEBP])
          }
          extension
          publicURL
        }
        cs_image_dark {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 690, formats: [WEBP])
          }
          extension
          publicURL
        }
        body {
          template
          ... on MarkdownRemarkFrontmatterBody {
            ...RateCardForm
            ...MarkdownWYSIWYG
            ...IconList
            ...Statistics
          }
        }
      }
    }
  }
`
